<template>
  <h-modal
    :loading="loading"
    :width="700"
    :title="title"
    :value="value"
    @input="(val) => $emit('input', val)"
  >
    <Form ref="form" :model="form" :rules="rules" :label-width="85">
      <Row>
        <Col :span="12">
          <FormItem label="订单标题">
            <Input readonly v-model="model.name"></Input>
          </FormItem>
        </Col>
        <Col :span="12">
          <FormItem label="订单类型">
            <Input readonly :value="orderTypeName"></Input>
          </FormItem>
        </Col>
        <Col :span="12">
          <FormItem prop="supplyFarmId" label="发货地点">
            <!-- <template v-if="!isCheck">
              <Select v-model="form.supplyFarmId">
                <Option v-for="farm in farms" :value="farm.id" :key="farm.id">
                  {{ farm.name }}
                </Option>
              </Select>
            </template> -->
            <Input readonly :value="form.supplyAddress"></Input>
          </FormItem>
        </Col>
        <Col :span="12">
          <FormItem prop="demandFarmId" label="收货地点">
            <!-- <template v-if="!isCheck">
              <Select v-model="form.demandFarmId">
                <Option v-for="farm in farms" :value="farm.id" :key="farm.id">
                  {{ farm.name }}
                </Option>
              </Select>
            </template> -->
            <Input readonly :value="form.demandAddress"></Input>
          </FormItem>
        </Col>
        <Col
          v-if="model && (model.orderStatus == 2 || model.orderStatus == 3)"
          :span="12"
        >
          <FormItem label="发货时间">
            <Input readonly v-model="model.deliveryTime"></Input>
          </FormItem>
        </Col>
        <Col v-if="model && model.orderStatus == 3" :span="12">
          <FormItem label="收货时间">
            <Input readonly v-model="model.receiverTime"></Input>
          </FormItem>
        </Col>
      </Row>
      <FormItem prop="remark" label="备注">
        <Input
          readonly
          :rows="4"
          type="textarea"
          v-model="model.remark"
        ></Input>
      </FormItem>
    </Form>
    <div>
      <div
        style="
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          padding-bottom: 12px;
        "
      >
        订单货品列表
      </div>
      <Table border :columns="tableColumns" :data="tableData"> </Table>
    </div>
    <div slot="footer" style="text-align: center">
      <template v-if="isCheck">
        <Button @click="() => $emit('input', false)" type="primary"
          >确定</Button
        >
      </template>
      <template v-else>
        <Button @click="submit" type="primary">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </template>
    </div>
  </h-modal>
</template>
<script>
import { Input } from "view-design";
import CU from "@/common/util";
import { mapState } from "vuex";

export default {
  props: {
    title: String,
    data: Object,
    value: Boolean,
    isCheck: Boolean,
    RowId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      farms: [],
      model: {},
      form: {
        supplyFarmId: "",
        supplyAddress: "",
        demandFarmId: "",
        demandAddress: "",
      },
      rules: {},
      tableColumns: [
        {
          title: "货品名称",
          render: (h, { row }) => {
            return <span>{row.productName}</span>;
          },
        },
        {
          title: "订单数量",
          render: (h, { row, index }) => {
            let iserror = this.errorNumRow.has(row.productId);
            return (
              <span class={iserror ? "ivu-form-item-error" : ""}>
                <Input
                  readonly={
                    this.isCheck &&
                    this.model &&
                    this.model.orderUserId == this.userId
                  }
                  on-input={(val) => this.validateNum(val, row, index)}
                  value={row.productNum}
                  size="small"
                ></Input>
              </span>
            );
          },
        },
        {
          title: "货品单位",
          render: (h, { row, index }) => {
            let iserror = this.errorUnitRow.has(row.productId);
            return (
              <span class={iserror ? "ivu-form-item-error" : ""}>
                <Input
                  readonly={
                    this.isCheck &&
                    this.model &&
                    this.model.orderUserId == this.userId
                  }
                  on-input={(val) => this.validateUnit(val, row, index)}
                  value={row.productUnit}
                  size="small"
                ></Input>
              </span>
            );
          },
        },
      ],
      tableData: [],
      // 验证订单数量未通过的行id
      errorNumRow: new Set(),
      //验证订单单位未通过的行id
      errorUnitRow: new Set(),
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.user?.id ?? "",
    }),
    // 订单类型
    orderTypeName() {
      let obj = this.model;
      if (!obj) return "";
      return obj.orderType == 1
        ? obj.demandUserId == this.userId
          ? "销售订单"
          : "采购订单"
        : obj.demandUserId == this.userId
        ? "采购订单"
        : "销售订单";
    },
    // 发货地点是否可编辑
    supplyEditable() {
      if (this.isCheck) return false;
      if (!this.model || !this.model.orderType) return false;
      if (this.model.orderType == 1 && this.model.demandUserId == this.userId)
        return true;
      if (this.model.orderType == 2 && this.model.orderUserId == this.userId)
        return true;
      return false;
    },
    // 收货地点是否可编辑
    demandEditable() {
      if (this.isCheck) return false;
      if (!this.model || !this.model.orderType) return false;
      if (this.model.orderType == 1 && this.model.orderUserId == this.userId)
        return true;
      if (this.model.orderType == 2 && this.model.demandUserId == this.userId)
        return true;
      return false;
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getOrderDetail();
        return;
      }
      this.$refs.form.resetFields();
      this.form.supplyAddress = "";
      this.form.demandAddress = "";
      this.model = {};
      this.tableData = [];
      this.errorUnitRow.clear();
      this.errorNumRow.clear();
    },
  },
  methods: {
    // 获取当前用户基地列表
    getFarms() {
      this.$post(this.$api.BASE_MANAGE.LIST)
        .then((res) => {
          this.farms = res.list;
        })
        .catch((e) => {
          this.farms = [];
        });
    },
    // 查询订单详情
    getOrderDetail() {
      this.$post(this.$api.ORDER_INFO.VIEW, { id: this.RowId })
        .then((res) => {
          this.tableData = res.itemList;
          delete res.itemList;
          this.model = { ...res };
          console.log(this.model)
          this.form.supplyAddress =
            res.orderType == 1 ? res.originAddress : res.receiveAddress;
          this.form.supplyFarmId =
            res.orderType == 1 ? res.demandFarmId : res.orderFarmId;
          this.form.demandFarmId =
            res.orderType == 2 ? res.demandFarmId : res.orderFarmId;
          this.form.demandAddress =
            res.orderType == 2 ? res.originAddress : res.receiveAddress;
        })
        .catch((e) => {
          console.log(e);
          this.$Message.warning("获取订单数据失败");
          this.$emit("input", false);
        });
    },
    // 验证货品数量
    validateNum(val, row, index) {
      if (CU.validateNumber(val, 0.01)) {
        this.errorNumRow.has(row.productId) &&
          this.errorNumRow.delete(row.productId);
      } else {
        this.errorNumRow.add(row.productId);
      }
      let newRow = {
        ...row,
        productNum: val,
      };
      this.tableData.splice(index, 1, newRow);
    },
    // 验证货品单位是否为空
    validateUnit(val, row, index) {
      let flag = false;
      if (!val) flag = true;
      if (typeof val == "string" && !val.trim()) flag = true;
      if (flag) {
        this.errorUnitRow.add(row.productId);
      } else {
        this.errorUnitRow.has(row.productId) &&
          this.errorUnitRow.delete(row.productId);
      }
      let newRow = {
        ...row,
        productUnit: val,
      };
      this.tableData.splice(index, 1, newRow);
    },
    submit() {
      this.$refs.form.validate((result) => {
        if (!result) return;
        if (this.errorNumRow.size > 0) return;
        if (this.errorUnitRow.size > 0) return;
        let params = {
          ...this.model,
          itemList: this.tableData,
        };
        this.loading = true;
        this.$post(this.$api.ORDER_INFO.ADD, params)
          .then((res) => {
            this.$Message.success(
              "订单已发出！关注订单状态，请前往订单管理页面"
            );
            this.$emit("input", false);
          })
          .finally(() => (this.loading = false));
      });
    },
  },
  mounted() {
    this.getFarms();
  },
};
</script>