<template>
  <!-- 订单管理 -->
  <div class="outer-page">
    <x-table
      @loadExpend="loadExpend"
      :no-data-text="CA('order_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <car-detail
      v-model="DriverModel"
      :title="DriverTitle"
      :DefluteValue="DefluteValue"
      :isCheck="defaultEdeit"
      :RowId="RowId"
      @refrestList="refrestList"
    ></car-detail>
    <transport-Model
      v-model="transportModel"
      :title="transportTitle"
      :DefluteValue="transporValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></transport-Model>
  </div>
</template>

<script>
import carDetail from "./orderModal";
import transportModel from "./transportModel";
import { mapState } from "vuex";
import CU from "@/common/util";
export default {
  name: "",
  components: {
    carDetail,
    transportModel,
  },
  data() {
    return {
      RowId: "",
      DriverModel: false,
      DriverTitle: "",
      transportModel: false,
      transportTitle: "",
      transporValue: null,
      DefluteValue: {},
      table: {
        columns: [
          {
            title: "订单发起时间",
            width: 170,
            align: "center",
            key: "orderTime",
          },
          {
            title: "编号",
            align: "center",
            width: 70,
            key: "orderNo",
          },
          
          {
            title: "订单名称",
            key: "name",
          },
          {
            title: "订单类型",
            width: 100,

            render: (h, { row }) => {
              let str;
              if (row.orderType == "0" && row.demandUserId == this.user.id)
                str = "供应订单";
              else if (row.orderType == "0" && row.orderUserId == this.user.id)
                str = "采购订单";
              else if (row.orderType == "1" && row.demandUserId == this.user.id)
                str = "采购订单";
              else if (row.orderType == "1" && row.orderUserId == this.user.id)
                str = "供应订单";
              return <div>{str}</div>;
            },
          },

          {
            title: "发货地址",
            tooltip: true,
            render: (h, { row }) => {
              let str;
              if (row.orderType == "0")
                str = row.originAddress;
              else if (row.orderType == "1")
                str = row.receiveAddress;
              return (
                <div>
                  {str}
                </div>
              );
            },
          },
          {
            title: "收货地址",
            tooltip: true,
            render: (h, { row }) => {
              let str;
              if (row.orderType == "0")
                str = row.receiveAddress;
              else if (row.orderType == "1")
                str = row.originAddress;
              return (
                <div>
                  {str}
                </div>
              );
            },
          },
          {
            title: "发货时间",
            width: 170,
            align: "center",
            key: "deliveryTime",
            render: (h, { row }) => {
              let str = row.deliveryTime || "--";
              return <span>{str}</span>;
            },
          },
          {
            title: "收货时间",
            width: 170,
            align: "center",
            key: "receiverTime",
            render: (h, { row }) => {
              let str = row.receiverTime || "--";
              return <span>{str}</span>;
            },
          },
          {
            title: "状态",
            width: 120,
            render: (h, { row }) => {
              let str =
                row.orderStatus == "0"
                  ? "待确认"
                  : row.orderStatus == "1"
                  ? "待发货"
                  : row.orderStatus == "2"
                  ? "转运中"
                  : "完成";
              return <span>{str}</span>;
            },
          },
          {
            title: "操作",
            width: 250,
            render: (h, { row }) => {
              return (
                <div>
                  {
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      订单详情
                    </a>
                  }
                  {
                    // this.CA("order_update") &&
                    // row.orderStatus == "0" &&
                    // localStorage.userId == row.orderUserId && (
                    //   <a
                    //     style="margin-right: 10px"
                    //     onClick={() => this.edit(row)}
                    //   >
                    //     编辑
                    //   </a>
                    // )
                  }
                  {localStorage.userId == row.demandUserId &&
                    row.orderStatus == "0" && (
                      <Poptip
                        confirm
                        transfer
                        title="是否确定订单?"
                        on-on-ok={() => this.confirmOrder(row.id)}
                      >
                        <a style="margin-right: 10px">确认订单</a>
                      </Poptip>
                    )}
                  {localStorage.userId == row.processId &&
                    row.orderStatus == "2" && (
                      <a
                        style="margin-right: 10px"
                        onClick={() => this.releaseTransport(row)}
                      >
                        转运调度
                      </a>
                    )}
                  {row.orderStatus == "1" &&
                    this.CA("order_starttransportation") && (
                      <a
                        style="margin-right: 10px"
                        onClick={() => this.processOrder(row.id)}
                      >
                        订单处理
                      </a>
                    )}
                  {localStorage.userId == (row.orderType == 0 ? row.orderUserId : row.demandUserId) &&
                    row.orderStatus == "2" && (
                      <a
                        style="margin-right: 10px"
                        onClick={() => this.finishOrder(row.id)}
                      >
                        完成订单
                      </a>
                    )}
                  {localStorage.userId == row.orderUserId &&
                    row.orderStatus == "0" && (
                      <Poptip
                        confirm
                        transfer
                        title="确定撤回订单吗?"
                        on-on-ok={() => this.delete(row.id)}
                      >
                        <a>撤回</a>
                      </Poptip>
                    )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          // add: {
          //   addBtnName: "新增订单",
          //   ca: "order_add",
          // },
          width: 200,
          filterBox: [
            {
              conditionName: "订单名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "状态",
              component: "select",
              field: "orderStatus",
              defaultValue: "",
              data: [
                { id: "0", val: "待确认" },
                { id: "1", val: "待发货" },
                { id: "2", val: "转运" },
                { id: "3", val: "完成" },
              ],
              parameterField: "id",
              showField: "val",
            },
            // {
            //   conditionName: "订单类型",
            //   component: "select",
            //   field: "orderStatus",
            //   defaultValue: "",
            //   data:[
            //       {id:'1',val:"销售订单"},
            //       {id:'2',val:"采购订单"},
            //     ],
            //   parameterField:"id",
            //   showField:"val"
            // },
          ],
          loadExpend: {
            loadExpendName: "导出",
          },
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      defaultEdeit: false,
      deptList: [],
      search_data: {},
      staticSearchData: {
        myOrder: "",
        transportOrder: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    loadExpend() {
      let columns = this.table.columns.map((item) => item.title);
      columns.pop();
      let data = this.table.data.map((row) => {
        let rowData = this.table.columns.map((column) => {
          if (column.title == "订单发起时间") {
            return row.orderTime;
          } else if (column.title == "编号") {
            return row.orderNo;
          } else if (column.title == "订单名称") {
            return row.name;
          } else if (column.title == "订单类型") {
            let str;
            if (row.orderType == "1" && row.demandUserId == this.user.id)
              str = "销售订单";
            else if (row.orderType == "1" && row.orderUserId == this.user.id)
              str = "采购订单";
            else if (row.orderType == "2" && row.demandUserId == this.user.id)
              str = "采购订单";
            else if (row.orderType == "2" && row.orderUserId == this.user.id)
              str = "销售订单";
            return str;
          } else if (column.title == "对方地址") {
            let str;
            if (row.orderType == "1" && row.demandUserId == this.user.id)
              str = row.originAddress;
            else if (row.orderType == "1" && row.orderUserId == this.user.id)
              str = row.receiveAddress;
            else if (row.orderType == "2" && row.demandUserId == this.user.id)
              str = row.receiveAddress;
            else if (row.orderType == "2" && row.orderUserId == this.user.id)
              str = row.originAddress;
            return str;
          } else if (column.title == "发货时间") {
            let str = row.deliveryTime || "--";
            return str;
          } else if (column.title == "收货时间") {
            let str = row.receiverTime || "--";
            return str;
          } else if (column.title == "状态") {
            let str =
              row.orderStatus == "0"
                ? "待确认"
                : row.orderStatus == "1"
                ? "待发货"
                : row.orderStatus == "2"
                ? "转运中"
                : "完成";
            return str;
          }
          return row[column.key];
        });
        return rowData;
      });
      CU.exportExcel(columns, data, "订单管理");
    },
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },
    //发布运输
    releaseTransport(row) {
      console.log(row)
      this.transportModel = true;
      this.transportTitle = "发布运输";
      this.transporValue = row;
      this.defaultEdeit = false;
    },
    //添加
    add() {
      this.DriverModel = true;
      this.DriverTitle = "添加订单";
      this.defaultEdeit = false;
      this.transporValue = null;
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize;
      this.getList();
    },
    refrestList() {
      this.getList();
    },
    //确认订单
    confirmOrder(id) {
      this.$post(this.$api.ORDER_INFO.CONFIRM, {
        id: id,
        confirm: true,
      }).then(() => {
        this.$Message.success("确认订单");
        this.getList();
      });
    },
    //完成订单
    finishOrder(id) {
      this.$post(this.$api.ORDER_INFO.FINISH, {
        id: id,
      }).then(() => {
        this.$Message.success("完成订单");

        this.getList();
      });
    },
    //管理员执行订单
    processOrder(id) {
      this.$post(this.$api.ORDER_INFO.PROCESS, {
        id: id,
      }).then(() => {
        this.$Message.success("完成订单");
        this.getList();
      });
    },
    getList() {
      if (!this.CA("order_check")) return;
      this.table.loading = true;
      this.$post(this.$api.ORDER_INFO.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
        ...this.staticSearchData,
      })
        .then((res) => {
          // console.log("=====>orderlist", res.list);
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      this.defaultEdeit = false;
      this.DriverModel = true;
      this.DriverTitle = "修改订单";
      this.RowId = row.id;
    },
    checkInfo(row) {
      this.DriverTitle = "查看订单";
      this.defaultEdeit = true;
      this.RowId = row.id;
      this.DriverModel = true;
    },

    delete(id) {
      this.$post(this.$api.ORDER_INFO.DELETE, {
        id: id,
      }).then(() => {
        this.$Message.success("撤回成功！");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        driverNo: "",
        companyNo: "",
        noValidPeriod: "",
        phone: "",
        email: "",
        birthday: "",
        address: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    if (this.$route.path == "/fwdorder") {
      this.staticSearchData = { transportOrder: "1" };
    } else if (
      this.$route.path == "/orderInfos" ||
      this.$route.path == "/lyorderInfoS"
    ) {
      this.staticSearchData = {
        myOrder: "1",
      };
    } else {
      this.staticSearchData = {};
    }
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>